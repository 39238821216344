import { Order } from "@/types/order.type";
import { Dispatch, SetStateAction, useState } from "react";
import { formatUserAddress } from "@/utils/formatUserAddress.util";
import { UserAddress } from "@/types/user.type";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from "@/components/ui/sheet";
import { formatToNaturalLanguage } from "@/utils/formatToNaturalLanguage.util";
import formatMoneyValue from "@/utils/formatMoneyValue.util";
import { PAYMENT_FLAGS } from "@/data/paymentFlags";
import { IconExternalLink, IconPhone } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import formatPhoneNumber from "@/utils/formatPhoneNumber.util";
import { Button } from "@/components/ui/button";
import CancelOrderAlert from "./cancel-order.alert";
import { Badge } from "@/components/ui/badge";
import { cn } from "@/lib/utils";
import { typesTraductions } from "@/data/typesTraductions";
import OrderStatusTimeline from "./order-status-timeline";
import MenuItemLogo from "@/components/fallbacks/logos/menu-item.logo";
import BusinessLogo from "@/components/fallbacks/logos/business-logo";

type OrderModalProps = {
  order: Order;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

export function ViewOrderSheet({ order, open, setOpen }: OrderModalProps) {
  const [cancelOpen, setCancelOpen] = useState(false);
  const navigate = useNavigate();
  const orderPrice = order.metadata.items.reduce(
    (acc, item) => acc + item.price * item.quantity,
    0
  );

  function handleRedirectToBusinessPage() {
    navigate(`/b/${order.business_id}`);
  }

  function handleCancelOrder() {
    setCancelOpen(true);
  }

  const showCancelButton =
    order.status !== "canceled" && order.status !== "done";

  return (
    <>
      <Sheet open={open} onOpenChange={setOpen}>
        <SheetContent className="sm:max-w-[600px] w-full text-gray-800 p-0 overflow-auto">
          <div className="flex flex-col divide-y">
            <SheetHeader className="p-4 bg-white z-10 flex flex-row items-start  text-start justify-between">
              <div className="flex flex-col gap-1">
                <SheetTitle className="text-xl font-bold">
                  Pedido #{order.id}
                </SheetTitle>
                <p className="text-sm text-gray-500">
                  {formatToNaturalLanguage(order.pending_at)}
                </p>
              </div>
              {showCancelButton && (
                <Button variant="outline" size="sm" onClick={handleCancelOrder}>
                  Cancelar pedido
                </Button>
              )}
            </SheetHeader>

            <div className="p-4 flex flex-col gap-4">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-3">
                  <BusinessLogo
                    className="w-12 h-12 rounded-full"
                    logo_url={order.metadata.business?.logo_url ?? null}
                  />
                  <div className="flex flex-col gap-1">
                    <p className="font-semibold">
                      {order.metadata.business.name}
                    </p>
                    {order.metadata.business.phone_number && (
                      <p className="text-sm text-gray-500 flex items-center gap-1">
                        <IconPhone size={14} />
                        {formatPhoneNumber(
                          order.metadata.business.phone_number
                        )}
                      </p>
                    )}
                  </div>
                </div>
                <Button
                  variant="ghost"
                  size="icon"
                  onClick={handleRedirectToBusinessPage}
                >
                  <IconExternalLink className="h-5 w-5" />
                </Button>
              </div>
              <div className="flex flex-col gap-2">
                <div className="flex items-center justify-between">
                  <p className="text-sm font-medium">Status</p>
                  <Badge
                    className={cn("text-white text-xs font-semibold", {
                      "bg-gray-600": order.status === "pending",
                      "bg-green-600": order.status === "done",
                      "bg-red-600": order.status === "canceled",
                      "bg-yellow-600": order.status === "production",
                      "bg-blue-600": order.status === "delivering",
                    })}
                  >
                    {typesTraductions[order.status]}
                  </Badge>
                </div>
                <OrderStatusTimeline order={order} />
              </div>
            </div>

            <div className="p-4 flex flex-col gap-2">
              <h2 className="font-semibold">Forma de entrega</h2>
              <p className="text-sm text-label-secondary">
                {formatUserAddress(order.metadata.user.address as UserAddress)}
              </p>
            </div>

            <div className="p-4 flex flex-col gap-2">
              <h2 className="font-semibold">Forma de pagamento</h2>
              <div className="flex items-center gap-2">
                {PAYMENT_FLAGS[
                  order.metadata.payment_method
                    .flag as keyof typeof PAYMENT_FLAGS
                ].image && (
                  <img
                    src={
                      PAYMENT_FLAGS[
                        order.metadata.payment_method
                          .flag as keyof typeof PAYMENT_FLAGS
                      ].image
                    }
                    alt={order.metadata.payment_method.flag}
                    className="w-6 h-6"
                  />
                )}
                <p>{order.metadata.payment_method.name}</p>
              </div>
              {order.metadata.payment_method.flag === "Dinheiro" && (
                <p className="text-sm text-label-secondary">
                  Troco para:{" "}
                  {formatMoneyValue(order.metadata.change_amount || 0)}
                </p>
              )}
            </div>

            {order.description && (
              <div className="p-4 flex flex-col gap-2">
                <h2 className="font-semibold">Observações do pedido</h2>
                <p className="text-sm text-label-secondary">
                  {order.description}
                </p>
              </div>
            )}

            <div className="p-4 flex flex-col gap-4">
              <h2 className="font-semibold">Itens do pedido</h2>
              <div className="flex flex-col gap-4">
                {order.metadata.items.map((item) => (
                  <div key={item.id} className="flex items-start gap-4">
                    <MenuItemLogo
                      logo_url={item.logo_url}
                      className={"w-16 h-16 rounded-lg object-cover"}
                    />
                    <div className="flex flex-col gap-1 flex-1">
                      <div className="flex items-start justify-between">
                        <h3 className="font-semibold">
                          {item.quantity}x {item.title}
                        </h3>
                      </div>
                      {item.optionals && item.optionals.length > 0 && (
                        <div className="flex flex-col gap-1">
                          {item.optionals.map((optional) => (
                            <p
                              key={optional.id}
                              className="text-sm text-label-secondary"
                            >
                              1x {optional.title}
                            </p>
                          ))}
                        </div>
                      )}
                      <p className="font-medium text-label-primary">
                        {formatMoneyValue(item.quantity * item.price)}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="p-4 flex flex-col gap-2">
              <h2 className="font-semibold">Resumo dos valores</h2>
              <div className="flex flex-col gap-2">
                <div className="flex justify-between">
                  <p className="text-label-secondary">Subtotal</p>
                  <p>{formatMoneyValue(orderPrice)}</p>
                </div>
                <div className="flex justify-between">
                  <p className="text-label-secondary">Taxa de entrega</p>
                  <p>{formatMoneyValue(0)}</p>
                </div>
                <div className="flex justify-between font-semibold text-lg">
                  <p>Total</p>
                  <p>{formatMoneyValue(orderPrice)}</p>
                </div>
              </div>
            </div>
          </div>
        </SheetContent>
      </Sheet>

      <CancelOrderAlert
        order={order}
        open={cancelOpen}
        setOpen={setCancelOpen}
      />
    </>
  );
}
